/* .footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  height: 50%;
}

.left-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40%;
}

.logoImage {
  margin-right: 10px;
  height: 100%;
  width: auto;
}

.links {
  display: flex;
  justify-content: space-around;
  margin: 10px;
}

.links a {
  padding: 10px;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
}

.right-section {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.anpcImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 40%;
  margin-top: 100px;
}

.logoAnpcImage {
  height: 100%;
  width: auto;
  margin-left: -90px;
}

.anpcImage {
  width: auto;
  height: 100%;
  margin-right: 10px;
}

@media only screen and (max-width: 1440px) and (min-width: 1201px) {
}
@media only screen and (max-width: 1200px) and (min-width: 1025px) {
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
} */

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
}

.left-section,
.right-section {
  width: 45%; /* Adjusted width for better spacing */
}

.left-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* Added margin for better spacing */
}

.logoImage {
  max-height: 100px; /* Adjusted max height for better scaling */
}

.links {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 10px 0;
}

.links a {
  padding: 5px; /* Adjusted padding for smaller screens */
  font-size: 14px; /* Adjusted font size for readability */
}

.social-icons {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.social-icons a {
  margin: 0 10px;
}

.right-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.anpcImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.logoAnpcImage {
  margin-right: 65%;
}

.logoAnpcImage,
.anpcImage {
  max-height: 100px; /* Adjusted max height for better scaling */
}

@media only screen and (max-width: 1024px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .left-section,
  .right-section {
    width: 100%;
    text-align: center; /* Centered text for better readability */
  }

  .links {
    flex-direction: column;
    align-items: center;
  }

  .links a {
    margin: 5px 0;
  }

  .social-icons {
    justify-content: center;
  }
  .logoAnpcImage {
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .logoImage {
    max-height: 40px;
  }

  .social-icons a {
    width: 40px;
    height: 40px;
  }

  .links a {
    font-size: 12px;
  }

  .logoAnpcImage {
    margin-right: 0;
  }

  .anpcImages {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    padding: 10px;
  }

  .logoAnpcImage {
    margin-right: 0;
  }

  .logoImage,
  .logoAnpcImage,
  .anpcImage {
    max-height: 30px;
  }

  .links a {
    font-size: 10px;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
  }
}

/* .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 20px 10px;
  overflow: hidden;
  height: 80px;
}

.header a {
  color: #213c59;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 25px;
  line-height: 30px;
  border-radius: 4px;
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
  padding: 0;
  display: flex;
  align-items: center;
  height: 150%;
}

.header a.logo img {
  height: 100%;
  max-height: 100%;
}

.header a:hover {
  background-color: #ddd;
  color: black;
}

.header a.active {
  background-color: dodgerblue;
  color: white;
}

.header-right {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1440px) and (min-width: 1201px) {
  .header {
    height: 70px;
    min-width: 1201px;
    max-width: 1440px;
  }

  .header a {
    font-size: 20px;
    line-height: 25px;
    border-radius: 4px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1025px) {
  .header {
    height: 60px;
    min-width: 1025px;
    max-width: 1200px;
  }

  .header a {
    font-size: 15px;
    line-height: 20px;
    border-radius: 4px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .header {
    height: 50px;
    min-width: 769px;
    max-width: 1024px;
  }

  .header a {
    font-size: 10px;
    line-height: 15px;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .header {
    height: 40px;
    min-width: 481px;
    max-width: 768px;
  }

  .header a {
    font-size: 8px;
    line-height: 10px;
    border-radius: 4px;
  }
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .header {
    height: 30px;
    min-width: 320px;
    max-width: 480px;
  }

  .header a {
    font-size: 8px;
    line-height: 5px;
    border-radius: 4px;
  }
} */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 20px 10px;
  overflow: hidden;
}

.header a {
  color: #213c59;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 25px;
  line-height: 30px;
  border-radius: 4px;
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
  padding: 0;
  display: flex;
  align-items: center;
}

.header a.logo img {
  height: 100%;
  max-height: 80px; /* Adjusted max height */
}

.header a:hover {
  background-color: #ddd;
  color: black;
}

.header a.active {
  background-color: dodgerblue;
  color: white;
}

.header-right {
  display: flex;
  align-items: center;
}

.menu-toggle {
  display: none;
  font-size: 30px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .header a {
    font-size: 18px;
    padding: 10px;
    margin-left: 20px;
  }

  .header-right {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 1000;
  }

  .header-right a {
    padding: 10px 20px;
    width: 100%;
    text-align: left;
  }

  .header-right.active {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .header a {
    font-size: 16px;
    padding: 8px;
    margin-left: 20px;
  }

  .header a.logo img {
    max-height: 60px;
  }
}

@media only screen and (max-width: 480px) {
  .header {
    padding: 10px 5px;
  }

  .header a {
    font-size: 14px;
    padding: 6px;
    margin-left: 20px;
  }

  .header a.logo img {
    max-height: 50px;
  }
}

/* .parter-container {
  position: relative;
  width: 100vw;
  height: auto;
  overflow: auto;
}

.parter-blueprint {
  width: 100%;
  height: auto;
  display: block;
}

.parter-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.parter-highlight {
  fill: transparent;
  stroke: transparent;
  stroke-width: 2;
  pointer-events: all;
}

.parter-highlight:hover {
  fill: rgba(198, 165, 34, 0.5);
  stroke: rgba(198, 165, 34, 0.8);
  stroke-width: 2;
  pointer-events: all;
  cursor: pointer;
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .parter-container {
    background-size: cover;
    height: 125%;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .parter-container {
    background-size: cover;
    height: 88%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .parter-container {
    background-size: cover;
    height: 53%;
  }
}
@media only screen and (max-width: 768px) and (min-width: 481px) {
  .parter-container {
    background-size: cover;
    height: 53%;
  }
}
@media only screen and (max-width: 480px) {
  body,
  html {
    background-color: white !important;
  }
  .parter-container {
    height: 33%;
  }
} */

/* Parter.css */
.parter-container {
  position: relative;
  width: 100vw;
  height: auto;
  overflow: auto;
}

.parter-blueprint {
  width: 100%;
  height: auto;
  display: block;
}

.parter-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.parter-highlight {
  fill: transparent;
  stroke: transparent;
  stroke-width: 2;
  pointer-events: all;
}

.parter-highlight:hover {
  fill: rgba(198, 165, 34, 0.5);
  stroke: rgba(198, 165, 34, 0.8);
  stroke-width: 2;
  pointer-events: all;
  cursor: pointer;
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .parter-container {
    height: 125%;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .parter-container {
    height: 88%;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .parter-container {
    height: 53%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .parter-container {
    height: 53%;
  }
}

@media only screen and (max-width: 480px) {
  body,
  html {
    background-color: white !important;
  }
  .parter-container {
    height: 33%;
  }
}

.confidentialitate {
  background-color: white;
  padding: 10px;
  margin: 20px; /* Adds space around the container */
  border-radius: 8px; /* Adds rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for better visibility */
  max-width: 800px; /* Limits the maximum width */
  margin-left: auto;
  margin-right: auto; /* Centers the container */
  font-family: "Bodoni", serif; /* Applies Bodoni font */
  font-weight: bold; /* Makes the text bold */
}

@media (max-width: 768px) {
  .confidentialitate {
    padding: 8px;
    margin: 10px;
    font-size: 16px; /* Adjusts font size for smaller screens */
  }
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  font-family: "Libre Bodoni", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: #213c59;
}

a {
  color: #213c59;
}

#root {
  height: 100%;
  width: 100%;
}

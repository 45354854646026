.ap915-scrollable-background {
  background-image: url("../../../../public/assets/images/ap9.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  width: auto;
  height: 720%;
  overflow: auto;
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .ap915-scrollable-background {
    background-size: cover;
    height: 680%;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .ap915-scrollable-background {
    background-size: cover;
    height: 470%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .ap915-scrollable-background {
    background-size: cover;
    height: 280%;
  }
}
@media only screen and (max-width: 768px) and (min-width: 481px) {
  .ap915-scrollable-background {
    background-size: cover;
    height: 280%;
  }
}
@media only screen and (max-width: 480px) {
  .ap915-scrollable-background {
    background-size: cover;
    height: 265%;
  }
}

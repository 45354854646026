.spcom-scrollable-background {
  background-image: url("../../../../public/assets/images/spcom.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  width: auto;
  height: 225%;
  overflow: auto;
}
@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .spcom-scrollable-background {
    height: 206%;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .spcom-scrollable-background {
    height: 145%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .spcom-scrollable-background {
    height: 81%;
  }
}
@media only screen and (max-width: 768px) and (min-width: 481px) {
  .spcom-scrollable-background {
    height: 87%;
  }
}
@media only screen and (max-width: 480px) {
  .spcom-scrollable-background {
    height: 70%;
  }
}

iframe {
  margin: 10px;
  border: 0;
}

.Contact {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  iframe {
    height: 700px;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  iframe {
    height: 700px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  iframe {
    height: 500px;
    width: 100%;
  }
  .Contact {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  iframe {
    height: 500px;
    width: 99%;
  }
  .Contact {
    flex-direction: column;
  }
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  iframe {
    height: 500px;
    width: 97%;
  }
  .Contact {
    flex-direction: column;
  }
}

.Container-Contact-Details {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: space-evenly !important;
  align-items: baseline !important;
  align-content: space-around !important;
  background-color: white !important;
  margin-bottom: 10px;
  height: auto;
  width: 100%;
}

.Left {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  align-content: space-around !important;
}

.Center {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  align-content: space-around !important;
}

.Right {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  align-content: space-around !important;
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .Container-Contact-Details {
    width: 100%;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .Container-Contact-Details {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .Container-Contact-Details {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .Container-Contact-Details {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .Container-Contact-Details {
    width: 100%;
  }
}

.ap6-scrollable-background {
  background-image: url("../../../../public/assets/images/ap6.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  width: auto;
  height: 560%;
  overflow: auto;
}
@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .ap6-scrollable-background {
    background-size: cover;
    height: 520%;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .ap6-scrollable-background {
    background-size: cover;
    height: 370%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .ap6-scrollable-background {
    background-size: cover;
    height: 220%;
  }
}
@media only screen and (max-width: 768px) and (min-width: 481px) {
  .ap6-scrollable-background {
    background-size: cover;
    height: 230%;
  }
}
@media only screen and (max-width: 480px) {
  .ap6-scrollable-background {
    background-size: cover;
    height: 225%;
  }
}

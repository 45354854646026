textarea {
  height: 100px;
}

input[type="text"],
input[type="tel"],
input[type="email"],
select,
textarea {
  width: calc(100% - 24px);
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #cc6600;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: auto;
  margin: 10p;
}

.container-contact-form {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  width: 40%;
  margin: 10px;
  padding: 10px;
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .container-contact-form {
    width: 98%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .container-contact-form {
    width: 95%;
  }
}
@media only screen and (max-width: 480px) {
  .container-contact-form {
    width: 93%;
  }
}

/* Apartments.css */
.Container-Sidebar {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}

.sidebar {
  margin: 0;
  padding: 0;
  background-color: white;
  position: sticky;
  width: 25%;
  height: auto;
}

.sidebar a {
  display: block;
  align-self: center;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.content {
  width: 75%;
  height: auto;
  background-color: white;
}

.each-slide-effect {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh; /* Ensure full viewport height */
  width: 100%;
}

.slide-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.apartmentsH1 {
  margin-left: 15px;
}

/* Full HD 1920x1080 */
@media only screen and (max-width: 1920px) and (min-width: 1367px) {
  .each-slide-effect {
    height: 100vh; /* Full height for large screens */
  }
}

/* HD 1366x768 */
@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .Container-Sidebar {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    margin-bottom: 10px;
  }

  .sidebar a {
    text-align: center;
    float: none;
  }

  .content {
    width: 100%;
  }

  .each-slide-effect {
    height: 80vh; /* Adjusted height for medium screens */
  }
}

/* 1280x1024 */
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .Container-Sidebar {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    margin-bottom: 10px;
  }

  .sidebar a {
    text-align: center;
    float: none;
  }

  .content {
    width: 100%;
  }

  .each-slide-effect {
    height: 75vh; /* Adjusted height for medium screens */
  }
}

/* 1024x1366 */
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .Container-Sidebar {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    margin-bottom: 10px;
  }

  .sidebar a {
    text-align: center;
    float: none;
  }

  .content {
    width: 100%;
  }

  .each-slide-effect {
    height: 70vh; /* Adjusted height for medium screens */
  }
}

/* 820x1180 */
@media only screen and (max-width: 820px) and (min-width: 769px) {
  .Container-Sidebar {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    margin-bottom: 10px;
  }

  .sidebar a {
    text-align: center;
    float: none;
  }

  .content {
    width: 100%;
  }

  .each-slide-effect {
    height: 60vh; /* Adjusted height for smaller screens */
  }
}

/* 768x1024 */
@media only screen and (max-width: 768px) and (min-width: 481px) {
  .Container-Sidebar {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    margin-bottom: 10px;
  }

  .sidebar a {
    text-align: center;
    float: none;
  }

  .content {
    width: 100%;
  }

  .each-slide-effect {
    height: 55vh; /* Adjusted height for smaller screens */
  }
}

/* 430x932 */
@media only screen and (max-width: 480px) {
  .Container-Sidebar {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
    margin-bottom: 10px;
  }

  .sidebar a {
    text-align: center;
    float: none;
  }

  .content {
    width: 100%;
  }

  .each-slide-effect {
    height: 40vh;
  }

  .slide-image {
    /* height: 100%;
    width: auto; */
    width: 100%;
    height: 40vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
